import { GridType } from '@mylight/data-model';
import RegistrationStatus from '../../constants/REGISTRATION_STATUS';

export default {
  MonthNames: {
    january: 'xxx',
    february: 'xxx',
    march: 'xxx',
    april: 'xxx',
    may: 'xxx',
    june: 'xxx',
    july: 'xxx',
    august: 'xxx',
    september: 'xxx',
    october: 'xxx',
    november: 'xxx',
    december: 'xxx'
  },
  InscriptionAlerts: {
    modalTitle: 'xxx',
    ALERT_GRID_TYPE_DIFFERENT_UCG_MODEL: 'xxx',
    ALERT_NUMBER_GMD_HIGHER_UCG_MODEL: 'xxx',
    ALERT_NUMBER_GMD_LOWER_UCG_MODEL: 'xxx',
    ALERT_NUMBER_TPH_HIGHER_UCG_MODEL: 'xxx',
    ALERT_NUMBER_TPH_LOWER_UCG_MODEL: 'xxx'
  },
  RegistrationStatus: {
    [RegistrationStatus.SETUP_OK]: 'xxx',
    [RegistrationStatus.USER_SETTINGS_SET]: 'xxx',
    [RegistrationStatus.USER_PASSWORD_SET]: 'xxx',
    [RegistrationStatus.USER_ACCEPTED_LICENCE]: 'xxx',
    [RegistrationStatus.OK]: 'xxx',
    [RegistrationStatus.SETUP_SELECTED_PRODUCTION_DEVICE]: 'xxx',
    [RegistrationStatus.SETUP_INSTALLATION_DETAILS_SET]: 'xxx',
    [RegistrationStatus.SETUP_INSTALLATION_DETAILS_SET2]: 'xxx',
    [RegistrationStatus.SETUP_DEVICES_REGISTERED]: 'xxx',
    [RegistrationStatus.SETUP_CONNECT_TADO]: 'xxx',
    [RegistrationStatus.SETUP_CHECK_WIRING]: 'xxx',
    [RegistrationStatus.SETUP_USER_REGISTERED]: 'xxx'
  },
  RegistrationStatusToolTips: {
    [RegistrationStatus.SETUP_OK]: 'xxx',
    [RegistrationStatus.USER_SETTINGS_SET]: 'xxx',
    [RegistrationStatus.USER_PASSWORD_SET]: 'xxx',
    [RegistrationStatus.USER_ACCEPTED_LICENCE]: 'xxx',
    [RegistrationStatus.OK]: 'xxx',
    [RegistrationStatus.SETUP_SELECTED_PRODUCTION_DEVICE]: 'xxx',
    [RegistrationStatus.SETUP_INSTALLATION_DETAILS_SET]: 'xxx',
    [RegistrationStatus.SETUP_INSTALLATION_DETAILS_SET2]: 'xxx',
    [RegistrationStatus.SETUP_DEVICES_REGISTERED]: 'xxx',
    [RegistrationStatus.SETUP_CONNECT_TADO]: 'xxx',
    [RegistrationStatus.SETUP_CHECK_WIRING]: 'xxx',
    [RegistrationStatus.SETUP_USER_REGISTERED]: 'xxx'
  },
  Customer: {
    [GridType.SINGLE_PHASE]: 'xxx',
    [GridType.THREE_PHASE]: 'xxx',
    accountCreationDate: 'xxx',
    restrictedAccess: 'xxx',
    addACustomer: 'xxx',
    address: 'xxx',
    birthDate: 'xxx',
    city: 'xxx',
    companyName: 'xxx',
    converterType: 'xxx',
    customers: 'xxx',
    device: 'xxx',
    deviceMac: 'xxx',
    controlledDeviceName: 'xxx',
    devicePower: 'xxx',
    devicesList: 'xxx',
    deviceStatus: 'xxx',
    deviceType: 'xxx',
    deviceTypeName: 'xxx',
    disabled: 'xxx',
    electricityContract: 'xxx',
    electricityProvider: 'xxx',
    email: 'xxx',
    emailAlert: 'xxx',
    enabled: 'xxx',
    fillMissingFields: 'xxx',
    globalConsumption: 'xxx',
    gridElectricalPhaseType: 'xxx',
    internetProvider: 'xxx',
    internetBoxModel: 'xxx',
    internetServiceProvider: 'xxx',
    installation: 'xxx',
    installer: 'xxx',
    installerLoadingError: 'xxx',
    lastConnection: 'xxx',
    ignored: 'xxx',
    lastConnections: 'xxx',
    lastRegisteredCustomers: 'xxx',
    firstName: 'xxx',
    licence: 'xxx',
    loginAs: 'xxx',
    maintainer: 'xxx',
    map: 'xxx',
    masterActivationSerialNumber: 'xxx',
    mobile: 'xxx',
    name: 'xxx',
    gridConsumption: 'xxx',
    no: 'xxx',
    panelCount: 'xxx',
    panelInstallation: 'xxx',
    panelTotalPower: 'xxx',
    panelManufacturer: 'xxx',
    performanceRecords: 'xxx',
    phone: 'xxx',
    powerContractType: 'xxx',
    powerContractOption: 'xxx',
    postalCode: 'xxx',
    production: 'xxx',
    rackingPower: 'xxx',
    registrationDate: 'xxx',
    registrationStatus: 'xxx',
    resendActivationEmail: 'xxx',
    resendActivationEmailSuccess: 'xxx',
    resendActivationEmailFailure: 'xxx',
    resendActivationEmailAlreadyActivated: 'xxx',
    saleOfSurplus: 'xxx',
    sellingEnergyPrice: 'xxx',
    smsAlert: 'xxx',
    street: 'xxx',
    tenant: 'xxx',
    chooseTenant: 'xxx',
    timeSlots: 'xxx',
    wattPeakByPanel: 'xxx',
    yes: 'xxx',
    alerts: 'xxx',
    newSubscription: 'xxx',
    inscription: 'xxx',
    inscriptionFull: 'xxx',
    continueInscription: 'xxx',
    pendingCustomerEmptyList: 'xxx',
    LastRegisteredCustomerEmptyList: 'xxx',
    boxSerialNumber: 'xxx',
    productionAndConsumption: 'xxx',
    on: 'xxx',
    offline: 'xxx',
    off: 'xxx',
    devices: 'xxx',
    system: 'xxx',
    customerEmptyList: 'xxx',
    emptyDeviceList: 'xxx',
    account: 'xxx',
    customer: 'xxx',
    services: 'xxx',
    contact: 'xxx',
    identity: 'xxx',
    country: 'xxx',
    OTHER: 'xxx',
    ENPHASE: 'xxx',
    pendingCustomers: 'xxx',
    pendingActivationCustomers: 'xxx',
    resendActivationEmailLabel: 'xxx',
    msbStatus_no_msb: 'xxx',
    msbStatus_pending: 'xxx',
    msbStatus_finished: 'xxx',
    searchAddress: 'xxx',
    longitude: 'xxx',
    latitude: 'xxx',
    canMoveCursorPositionInstallation: 'xxx',
    newAddressSearch: 'xxx',
    goToCustomer: 'xxx'
  },
  ContractName: {
    flat: 'xxx',
    custom: 'xxx'
  },
  Login: {
    connection: 'xxx',
    email: 'xxx',
    error: 'xxx',
    password: 'xxx',
    wrongPassword: 'xxx',
    accountCreationLink: 'xxx'
  },
  SubMenu: {
    identity: 'xxx',
    contactPro: 'xxx',
    installation: 'xxx',
    system: 'xxx',
    support: 'xxx',
    service: 'xxx'
  },
  Menu: {
    dashboard: 'xxx',
    customers: 'xxx',
    company: 'xxx',
    tools: 'xxx',
    support: 'xxx',
    myAccount: 'xxx',
    proPortal: 'xxx',
    proSizer: 'xxx',
    disconnect: 'xxx'
  },
  Support: {
    coordinates: 'xxx',
    email: 'xxx',
    franceCustomerSupportNumber: 'xxx',
    franceTechnicalSupportNumber: 'xxx',
    france: 'xxx',
    switzerland: 'xxx',
    switzerlandCustomerSupportNumber: 'xxx',
    switzerlandTechnicalSupportNumber: 'xxx',
    supportCustomerEmail: 'xxx',
    supportTechnicalEmail: 'xxx',
    technicalTitle: 'xxx',
    customerTitle: 'xxx',
    subscriptionPending: 'xxx',
    subscriptionDone: 'xxx',
    devicesNotInstalled: 'xxx',
    loginAsLabel: 'xxx',
    workingHours: 'xxx',
    pairingToolkit: 'xxx',
    access: 'xxx',
    documentationTitle: 'xxx',
    technicalDocumentation: 'xxx',
    gettingStartedGuide: 'xxx',
    faq: 'xxx'
  },

  Common: {
    addressNotFound: 'xxx',
    email: 'xxx',
    support: 'xxx',
    MAINTAINER: 'xxx',
    ADMINISTRATOR: 'xxx',
    type: 'xxx',
    INSTALLER: 'xxx',
    goBackLink: 'xxx',
    from: 'xxx',
    to: 'à',
    sure: 'xxx',
    zipCode: 'xxx',
    country: 'xxx',
    city: 'xxx',
    address: 'xxx',
    additionalAddress: 'xxx',
    select: 'xxx',
    selectError: 'xxx',
    save: 'xxx',
    connect: 'xxx',
    checkAddress: 'xxx',
    yes: 'xxx',
    no: 'xxx',
    cancel: 'xxx',
    add: 'xxx',
    remove: 'xxx',
    associate: 'xxx',
    next: 'xxx',
    choseAddress: 'xxx',
    addressError: 'xxx',
    emailTaken: 'xxx',

    tenantError: 'xxx',
    maintainerError: 'xxx',
    zipCodeError: 'xxx',
    cityError: 'xxx',
    countryError: 'xxx',

    firstNameError: 'xxx',
    lastNameError: 'xxx',
    phoneError: 'xxx',
    emailAddressError: 'xxx',
    birthDateError: 'xxx',

    createCustomerTitle: 'xxx',
    geoLocation: 'xxx',

    afterCreateModalTitle: 'xxx',
    phoneRequired: 'xxx',

    goToFinishInstallation: 'xxx',
    goToIdentity: 'xxx',
    tip: 'xxx',
    plus: 'xxx',
    minus: 'xxx',
    day: 'xxx',
    month: 'xxx',
    year: 'xxx',
    redirecting: 'xxx',
    warning: 'xxx',
    loadError: 'xxx',
    retry: 'xxx',
    agree: 'xxx',
    invalid: 'xxx',
    disconnect: 'xxx',
    listFilter_status_no: 'xxx',
    listFilter_status_pending: 'xxx',
    listFilter_status_ok: 'xxx',
    listFilter_msbStatus_no: 'xxx',
    listFilter_msbStatus_pending: 'xxx',
    listFilter_msbStatus_ok: 'xxx',
    loading: 'xxx',
    noInstallation: 'xxx',
    none_result: 'xxx',
    send: 'xxx',
    chooseFile: 'xxx',
    or: 'xxx',
    step: 'xxx',
    nextStep: 'xxx',
    prevStep: 'xxx',
    unknown: 'xxx'
  },
  Installation: {
    [GridType.SINGLE_PHASE]: 'xxx',
    [GridType.THREE_PHASE]: 'xxx',
    systemOffline: 'xxx',
    saveInstallation: 'xxx',
    pdl: 'xxx',
    pdlWithMsbNotEditable: 'xxx',
    pdlWithMsbInfo: 'xxx',
    pdlForMsbInfo: 'xxx',
    invalidPdlFormat: 'xxx',
    invalidPdlFormatForMsb: 'xxx',
    invalidMsbSnFormat: 'xxx',
    invalidCaeFormat: 'xxx',
    invalidEnedisConnectNumberFormat: 'xxx',
    invalidConsuelDeclaredPowerFormat: 'xxx',
    invalidConnectionPowerFormat: 'xxx',
    singlePhaseInvalidConnectionPowerInjectionPower: 'xxx',
    threePhaseInvalidConnectionPowerInjectionPower: 'xxx',
    invalidConnectionPowerInjectionPowerHeader: 'xxx',
    tooHighConnectionPowerInjectionPower: 'xxx',
    invalidStrasbourgInjectionPdlNumberFormat: 'xxx',
    invalidInjectionPdlFormatForStrasbourgElectricity: 'xxx',
    invalidConsuelNumberFormat: 'xxx',
    start: 'xxx',
    end: 'xxx',
    startComeBeforeEndWarning: 'xxx',
    hcRangeOverlapWarning: 'xxx',
    masterTypeInternetConnection: 'xxx',
    simRouterReference: 'xxx',
    simSerialNumber: 'xxx',
    ethernetConnectionType: 'xxx',
    wifiConnectionType: 'xxx',
    plc_mylightConnectionType: 'xxx',
    plc_otherConnectionType: 'xxx',
    gsmConnectionType: 'xxx',
    sellingPower: 'xxx',
    errorSellingPower: 'xxx',
    unableToSellPowerWithMSB: 'xxx',
    errorPanelCount: 'xxx',
    errorPanelWattPeak: 'xxx',
    warningResetFunctionCompositeDevice: 'xxx',
    areYouSureToChangedGridType: 'xxx',
    france: 'xxx',
    swiss: 'xxx'
  },
  InstallationEdit: {
    title: 'xxx',
    saveEditButton: 'xxx',
    savedMessage: 'xxx'
  },
  404: {
    notFoundCode: 'xxx',
    notFound: 'xxx',
    lostSentence: 'xxx',
    backHome: 'xxx'
  },
  LoginAs: {
    loginAsLink: 'xxx'
  },
  ForgotPassword: {
    forgotPasswordLink: 'xxx',
    introductionMsg: 'xxx',
    emailSendMsg: 'xxx',
    error: 'xxx',
    resetButton: 'xxx'
  },
  ChangePassword: {
    changePasswordDescription: 'xxx',
    newPasswordLabel: 'xxx',
    repeatedNewPasswordLabel: 'xxx',
    saveButton: 'xxx',
    passwordsAreNotTheSame: 'xxx',
    passwordToShort: 'xxx',
    passwordChangedSuccessfully: 'xxx',
    passwordChangedFailed: 'xxx',
    invalidRecoveryCodeError: 'xxx',
  },
  Tenant: {
    myhome: 'xxx',
    myhomeFullName: 'xxx',
    domuneo: 'xxx',
    domuneoFullName: 'xxx',
    planeteoui: 'xxx',
    planeteouiFullName: 'xxx'
  },
  DeviceType: {
    mst: 'xxx',
    mst_g3: 'xxx',
    eth: 'xxx',
    sw: 'xxx',
    sw_g3: 'xxx',
    gmd: 'xxx',
    gmd_g3: 'xxx',
    enp: 'xxx',
    cmp: 'xxx',
    wsrhr: 'xxx',
    wsrts: 'xxx',
    wsrvl: 'xxx',
    tdac: 'xxx',
    tdht: 'xxx',
    tph: 'xxx',
    tado_ac: 'xxx',
    tado_heater: 'xxx',
    rs485: 'xxx',
    modbus_box: 'xxx',
    modbus_part: 'xxx'
  },
  CustomerEdit: {
    title: 'xxx',
    saveEditButton: 'xxx',
    firstName: 'xxx',
    lastName: 'xxx',
    phoneNumber: 'xxx',
    mobileNumber: 'xxx',
    zipCode: 'xxx',
    city: 'xxx',
    editCustomerSuccess: 'xxx'
  },
  Messages: {
    deviceReadingsIncomplete: 'xxx'
  },
  Dashboard: {
    numberOfInstallation: 'xxx',
    operational: 'xxx',
    pending: 'xxx',
    prosizerCallToAction: 'xxx',
    prosizerLinkName: 'xxx'
  },
  Diagnostic: {
    startButton: 'xxx',
    incorrectPhase: 'xxx',
    diagnosticStatusPending: 'xxx'
  },
  DiagnosticSummary: {
    summaryTitle: 'xxx',
    testNameLabel: 'xxx',
    testSetStatus: 'xxx',
    pending: 'xxx',
    ok: 'xxx',
    Failure: 'xxx',
    create: 'xxx',
    testErrorLabel: 'xxx',
    testOkLabel: 'xxx',
    date: 'xxx',
    emptyTestList: 'xxx',
    details: 'xxx',
    menuTitle: 'xxx',
    emptyTestDetailsList: 'xxx',
    Error: 'xxx',
    Causes: 'xxx',
    Solutions: 'xxx'
  },
  MySmartBattery: {
    battery: 'xxx',
    addBattery: 'xxx',
    addBatteryInfo: 'xxx',
    addBatteryELD: 'xxx',
    addBatteryELDInfo: 'xxx',
    addBox: 'xxx',
    createBattery: 'xxx',
    updateBattery: 'xxx',
    deleteBattery: 'xxx',
    caeNumber: 'xxx',
    pdlNumber: 'xxx',
    enedisConnectNumber: 'xxx',
    consuelNumber: 'xxx',
    consuelDeclaredPower: 'xxx',
    consuelDeclaredPowerTooltip: 'xxx',
    connectionPower: 'xxx',
    singlePhaseConnectionPowerTooltip: 'xxx',
    threePhaseConnectionPowerTooltip: 'xxx',
    strasbourgInjectionPdlNumber: 'xxx',
    addSerialNumber: 'xxx',
    enterSerialNumber: 'xxx',
    snLimit: 'xxx',
    snExist: 'xxx',
    invalidSN: 'xxx',
    scan: 'xxx',
    serialNumber: 'xxx',
    serialNumberBlank: 'xxx',
    serialNumbers_PLANETE_OUI: 'xxx',
    serialNumbers_MYLIGHT: 'xxx',
    volume: 'xxx',
    status: 'xxx',
    pdl: 'xxx',
    pdlError: 'xxx',
    caeNumberError: 'xxx',
    enedisConnect: 'xxx',
    addingBoxesError: 'xxx',
    msb_SN_filled_true: 'xxx',
    msb_SN_filled_false: 'xxx',
    msb_SN_filled_details: 'xxx',
    msb_AC_filled_true: 'xxx',
    msb_AC_filled_false: 'xxx',
    msb_AC_filled_details: 'xxx',
    msb_provider_registration_ok_true: 'xxx',
    msb_provider_registration_ok_false: 'xxx',
    msb_provider_registration_ok_details: 'xxx',
    msb_enedis_ok_true: 'xxx',
    msb_enedis_ok_false: 'xxx',
    msb_enedis_ok_details: 'xxx',
    msb_ok_true: 'xxx',
    msb_ok_false: 'xxx',
    documentsTitle: 'xxx',
    arpeTitle: 'xxx',
    caeTitle: 'xxx',
    planeteOuiMsbDocumentsInfo: 'xxx',
    arpeInfo: 'xxx',
    caeInfo: 'xxx',
    arpeDownload: 'xxx',
    arpeUpload: 'xxx',
    caeUpload: 'xxx',
    choosePdf: 'xxx',
    importFilePdfFormat: 'xxx',
    importARPEPdfFile: 'xxx',
    pdfUploadInfo_ARPE: 'xxx',
    pdfUploadInfo_CAE: 'xxx',
    needPdf: 'xxx',
    pdfSendingError: 'xxx',
    pdfSendingSuccess: 'xxx',
    sendDateCAE: 'xxx',
    sendDateARPE: 'xxx',
    notSending: 'xxx',
    date: 'xxx',
    activationStatus: {
      activated: 'xxx',
      created: 'xxx'
    },
    enteredOn: 'xxx',
    onHold: 'xxx',
    energyDistributors: {
      ENEDIS: 'xxx',
      ES: 'xxx'
    },
    energyDistributor: 'xxx',
    networkInjection: 'xxx',
    errorLoadingMsbActivation: 'xxx',
    deletionModalTitle: 'xxx',
    deletionModalBodyTitle: 'xxx',
    deletionModalBodyContent: 'xxx',
    deletionButton: 'xxx',
    deletionCancelButton: 'xxx',
    deletionSuccess: 'xxx',
    enedisGridInjectionFormDescription: 'xxx',
    enedisGridInjectionFormEnedisConnectFieldDescription: 'xxx',
    enedisGridInjectionFormConsuelNumberFieldDescription: 'xxx',
    enedisGridInjectionFormDeclaredInstalledPowerFieldDescription: 'xxx',
    enedisGridInjectionFormConnectionPowerFieldDescription: 'xxx',
    strasbourgElectricityAdministrativeProcedureCaeNumberFieldDescription: 'xxx',
    strasbourgElectricityAdministrativeProcedureInjectionPdlNumberFieldDescription: 'xxx',
    errors: {
      serialNumberFormatNotValid: 'xxx',
      serialNumberAlreadyUsed: 'xxx',
      serialNumberDoesntExist: 'xxx',
      updateSerialNumberFailed: 'xxx',
      pdlNumberFormatNotValid: 'xxx',
      pdlNumberAlreadyUsed: 'xxx',
      updatePdlNumberFailed: 'xxx',
      requestEnedisGridInjectionFailed: 'xxx',
      requestStrasbourgElectricityAdministrativeProcedureFailed: 'xxx',
      createMsbFailed: 'xxx',
      eldFormatInvalid: 'xxx',
      msbApiUpdateStrasbourgInjectionPdlNotUnique: 'xxx',
    }
  },
  BarcodeReader: {
    scan: 'xxx',
    rescan: 'xxx',
    scanTitle: 'xxx',
    scanInstruction: 'xxx',
    wrongCode: 'xxx',
    correctCode: 'xxx',
    UCGHelp: 'xxx',
    MSTHelp: 'xxx',
    MSBHelp: 'xxx'
  },
  TestTitle: {
    'Test MST to the server connection': 'xxx',
    'GMD communication test': 'xxx',
    'TPH communication test': 'xxx',
    'ETH communication test': 'xxx',
    'SW communication test': 'xxx',
    'Test water heater consumption on TPH': 'xxx',
    'Test Single Phase Consumption Measurement': 'xxx',
    'Test single-phase Production measurement': 'xxx'
  },
  TestErrorCauses: {
    COM030: 'xxx',
    COM032: 'xxx',
    COM033: 'xxx',
    COM034: 'xxx',
    COM035: 'xxx',
    RCE050: 'xxx',
    TCM010_01: 'xxx',
    TCM010_02: 'xxx',
    TCM010_03: 'xxx',
    TCM011_01: 'xxx',
    TCM011_02: 'xxx',
    TCM011_03: 'xxx'
  },
  TestErrorMessage: {
    COM030: 'xxx',
    COM032: 'xxx',
    COM033: 'xxx',
    COM034: 'xxx',
    COM035: 'xxx',
    RCE050: 'xxx',
    TCM010_01: 'xxx',
    TCM010_02: 'xxx',
    TCM010_03: 'xxx',
    TCM011_01: 'xxx',
    TCM011_02: 'xxx',
    TCM011_03: 'xxx'
  },
  TestErrorSolutions: {
    COM030: 'xxx',
    COM032: 'xxx',
    COM033: 'xxx',
    COM034: 'xxx',
    COM035: 'xxx',
    RCE050: 'xxx',
    TCM010_01: 'xxx',
    TCM010_02: 'xxx',
    TCM010_03: 'xxx',
    TCM011_01: 'xxx',
    TCM011_03: 'xxx',
    TCM011_02: 'xxx'
  },
  UCG: {
    numberOfInstallation: 'xxx',
    operational: 'xxx',
    pending: 'xxx',
    doesNotHaveUCG: 'xxx',
    iHaveUCG: 'xxx',
    noUCG: 'xxx',
    ucg: 'xxx',
    scan: 'xxx',
    scanTitle: 'xxx',
    ucgHelp: 'xxx',
    ucgHelpMobile: 'xxx'
  },
  UPM: {
    whereIsSerial: 'xxx',
    positionSerial: 'xxx'
  },
  WModbusPart: {
    whereIsSerial: 'xxx',
    positionSerial: 'xxx'
  },
  Master: {
    explainBarCodeReader: 'xxx',
    barCodeList: 'xxx',
    master: 'xxx',
    masterHelp: 'xxx',
    masterHelpMobile: 'xxx',
    MAC: 'xxx',
    MSTCODE: 'xxx',
    sMAC: 'xxx',
    sMSTCODE: 'xxx',
    masterError: 'xxx',
    scanTitle: 'xxx',
    scan: 'xxx',
    invalid: 'xxx',
    timeout: 'xxx',
    eraseMessage: 'xxx',
    updateMST: 'xxx',
    connectedMST: 'xxx',
    disconnectedMST: 'xxx',
    recontactMaster: 'xxx',
    selectTypeConnection: 'xxx',
    selectMasterDisconnect: 'xxx',
    receptionSignalNotOpti: 'xxx',
    extendingAntenna: `xxx`,
    extendingModem: `xxx`,
    reorientAntenna: `xxx`,
    contactSupport: `xxx`,
    warningIntermittentConnexion: `xxx`,
    informations: 'xxx',
    informationRebootMasterWhenChangeTypeConnection: `xxx`
  },

  RegistrationMenu: {
    pv_installation: 'xxx',
    partnership: 'xxx',
    battery: 'xxx',
    ucg: 'xxx',
    master: 'xxx',
    devices: 'xxx',
    functions: 'xxx',
    wiring: 'xxx',
    stepperTitle: 'xxx',
    mainTitle: 'xxx',
    finish: 'xxx',
    summary: 'xxx',
    devicesSummary: 'xxx'
  },
  DevicesInscription: {
    finalizeInstallation: 'xxx',
    deviceNotAssigned: 'xxx',
    warningYouDontHaveAssignedSomeDevice: 'xxx',
    inscriptionInProgress: 'xxx',
    notPossibleToDefineTwoGlobalConsumptionCounter: 'xxx',
    functionAlreadyAffectedAtAnOtherDevice: 'xxx',
    continueInscription: 'xxx',
    waitingFinishInscription: 'xxx'
  },
  Devices: {
    counter: 'xxx',
    activationCode: 'xxx',
    roomToDefine: 'xxx',
    type: 'xxx',
    name: 'xxx',
    mac: 'xxx',
    room: 'xxx',
    saved: 'xxx',
    unsaved: 'xxx',
    on: 'xxx',
    offline: 'xxx',
    deviceState: 'xxx',
    stateOn: 'xxx',
    stateOff: 'xxx',
    alaska_home: 'xxx',
    doneListTitle: 'xxx',
    todoListTitle: 'xxx',
    unusedFunctions: 'xxx',
    emptyFunctionsList: 'xxx',
    emptyDoneList: 'xxx',
    roomType: 'xxx',
    roomName: 'xxx',
    deviceList: 'xxx',
    firmware: 'xxx',
    function: 'xxx',
    swG3Function: 'xxx',
    defineFunction: 'xxx',
    listTitle: 'xxx',
    modbusDevicesTitle: 'xxx',
    wmodbusDevicesTitle: 'xxx',
    modbusAddress: 'xxx',
    modbusReference: 'xxx',
    refreshList: 'xxx',
    split: 'xxx',
    merge: 'xxx',
    functionName: 'xxx',
    swG3FunctionName: 'xxx',
    functionType: 'xxx',
    functionEditionTitle: 'xxx',
    affectFunction: 'xxx',
    greenplay: 'xxx',
    deviceEditing: 'xxx',
    negativePower: 'xxx',
    noFunctionLabel: 'xxx',
    greenPlayLabel: 'xxx',
    plcType: 'xxx',
    rs485Type: 'xxx',
    addDeviceTitle: 'xxx',
    addDeviceType: 'xxx',
    addDeviceTitlePLC: 'xxx',
    addDeviceTitleRS485: 'xxx',
    addDevicePLC: 'xxx',
    addDeviceRS485: 'xxx',
    addDeviceWMODBUS: 'xxx',
    selectWModbusDeviceType: 'xxx',
    addDeviceTitleWModbusBox: 'xxx',
    addDeviceWModbusBoxSerial: 'xxx',
    addDeviceTitleWModbusPart: 'xxx',
    addDeviceWModbusPartSerial: 'xxx',
    connectedRS485Devices: 'xxx',
    chooseReference: 'xxx',
    chooseAddress: 'xxx',
    macOrAC: 'xxx',
    invalidMacOrAC: 'xxx',
    plcConnectingInProgress: 'xxx',
    plcConnectingInProgressExtended: 'xxx',
    reference: 'xxx',
    deviceDisconnectedError: 'xxx',
    disconnectedModbusDevice: 'xxx',
    forbiddenDeviceForGridType: 'xxx',
    deletingModbusDevice: 'xxx',
    setGridType: 'xxx',
    measureOfPrefix: 'xxx',
    measureAndControlOfPrefix: 'xxx',
    controlOfPrefix: 'xxx',
    swG3WaterHeaterFunctionSelected: 'xxx',
    gmdG3CTWaterHeaterFunctionSelected: 'xxx'
  },
  RoomName: {
    workshop: 'xxx',
    other_area_type: 'xxx',
    library: 'xxx',
    washroom: 'xxx',
    office: 'xxx',
    cellar: 'xxx',
    storeroom: 'xxx',
    bedroom: 'xxx',
    boiler_room: 'xxx',
    corridor: 'xxx',
    kitchen: 'xxx',
    outbuilding: 'xxx',
    dressing_room: 'xxx',
    entry: 'xxx',
    garage: 'xxx',
    alaska_home: 'xxx',
    garden: 'xxx',
    swimming_pool: 'xxx',
    dining_room: 'xxx',
    bathroom: 'xxx',
    shower_room: 'xxx',
    playroom: 'xxx',
    sports_room: 'xxx',
    living_room: 'xxx',
    porch: 'xxx',
    water_closet: 'xxx'
  },
  DevicesFunctions: {
    asoka_red_plug: 'xxx',
    aspirator: 'xxx',
    other_device_type: 'xxx',
    bbq_hot_stone: 'xxx',
    battery: 'xxx',
    kettle: 'xxx',
    wine_cellar: 'xxx',
    water_heater: 'xxx',
    composite_device: 'xxx',
    asoka_electric_counter: 'xxx',
    freezer: 'xxx',
    game_console: 'xxx',
    electric_car: 'xxx',
    speaker: 'xxx',
    iron: 'xxx',
    oven: 'xxx',
    refrigerator: 'xxx',
    refrigerator_freezer: 'xxx',
    deep_fryer: 'xxx',
    toaster: 'xxx',
    stereo_system: 'xxx',
    home_cinema_system: 'xxx',
    production_counter_ignored: 'xxx',
    printer: 'xxx',
    lamp: 'xxx',
    dishwasher: 'xxx',
    washing_machine: 'xxx',
    washing_drying_machine: 'xxx',
    dvd_player: 'xxx',
    coffee_machine: 'xxx',
    microwave_oven: 'xxx',
    computer: 'xxx',
    production_counter: 'xxx',
    pump: 'xxx',
    heater: 'xxx',
    radio: 'xxx',
    razor: 'xxx',
    alarm_clock: 'xxx',
    food_processor: 'xxx',
    hair_dryer: 'xxx',
    dryer: 'xxx',
    phone: 'xxx',
    tv: 'xxx',
    ventilator: 'xxx',
    video_projector: 'xxx',
    virtual: 'xxx'
  },
  DeviceDetail: {
    mst1: 'xxx',
    mst2: 'xxx',

    mst_g3mono1: 'xxx',
    mst_g3mono2: 'xxx',
    mst_g3mono3: 'xxx',
    mst_g3mono4: 'xxx',
    mst_g3mono5: 'xxx',

    mst_g3tri1: 'xxx',
    mst_g3tri2: 'xxx',
    mst_g3tri3: 'xxx',
    mst_g3tri4: 'xxx',
    mst_g3tri5: 'xxx',

    eth1: 'xxx',
    eth2: 'xxx',
    sw1: 'xxx',
    sw2: 'xxx',
    sw_g31: 'xxx',
    sw_g32: 'xxx',
    gmdmono1: 'xxx',
    gmdmono2: 'xxx',
    gmdtri1: 'xxx',
    gmdtri2: 'xxx',
    gmd_g3mono1: 'xxx',
    gmd_g3mono2: 'xxx',
    gmd_g3tri1: 'xxx',
    gmd_g3tri2: 'xxx',
    rs485_mc11: 'xxx',
    rs485_mc12: 'xxx',
    rs485_mc31: 'xxx',
    rs485_mc32: 'xxx'
  },
  Partnerships: {
    partners: 'xxx',
    enphaseSystemName: 'xxx',
    tadoSystemName: 'xxx',
    wiserSystemName: 'xxx',
    validate: 'xxx',
    connect: 'xxx',
    cancel: 'xxx',
    connectedStatus: 'xxx',
    inProgressStatus: 'xxx',
    disconnectedStatus: 'xxx',
    connectingInProgress: 'xxx'
  },
  EnphasePartnership: {
    emailExist: 'xxx',
    email: 'xxx',
    emailConfirmation: 'xxx',
    emailWarning: 'xxx',
    enphaseSystemId: 'xxx',
    enphaseInstallerId: 'xxx',
    whereIsInstallerID: {
      title: `xxx`,
      step1: `xxx`,
      step2: `xxx`
    },
    enphaseInstallerIdHelp: 'xxx',
    enphaseInstallerIdDescription: 'xxx',
    enphaseDetails: 'xxx',
    envoySerialNumber: 'xxx',
    envoyRegistered: 'xxx',
    wrongEnvoyFormat: 'xxx',
    updateEmail: 'xxx',
    wrongSystemIdFormat: 'xxx',
    wrongEmail: 'xxx',
    wrongInstallerIdFormat: 'xxx'
  },
  EnphaseErrors: {
    email: 'xxx',
    emailExist: 'xxx',
    registration: 'xxx',
    serialNumber: 'xxx',
    activationCode: 'xxx',
    owner: 'xxx',
    systemId: 'xxx',
    emailUpdate: 'xxx',
    unknown: 'xxx'
  },
  TadoPartnership: {
    login: 'xxx',
    connectingTado: 'xxx',
    selectTadoHome: 'xxx',
    tadoHome: 'xxx',
    connectTadoHome: 'xxx',
    resumeTadoConnecting: 'xxx',
    disconnectTado: 'xxx',
    detectedDevices: 'xxx',
    connectedDevices: 'xxx',
    reopenTadoLoginWindow: 'xxx',
    validate: 'xxx',
    devicesAddingError: 'xxx',
    devicesAddingSuccess: 'xxx',
    deviceRemovingError: 'xxx',
    deviceRemovingSuccess: 'xxx',
    tadoDisconnectingError: 'xxx',
    tadoDisconnectingSuccess: 'xxx',
    noTadoDevices: 'xxx',
    noDetectedDevices: 'xxx'
  },
  TadoErrors: {
    getDevices: 'xxx',
    homeNotFound: 'xxx'
  },
  Success: {
    updateCustomer: 'xxx',
    updateInstallation: 'xxx',
    updateUCG: 'xxx',
    updateMaster: 'xxx',
    updateDevice: 'xxx',
    updateDevices: 'xxx',
    updateFunctions: 'xxx',
    updateMSB: 'xxx',
    finishInscription: 'xxx'
  },
  SummaryWarnings: {
    missingPLCHub: 'xxx'
  },
  Errors: {
    saveCustomer: 'xxx',
    saveCustomerSrs: 'xxx',
    assignError: 'xxx',
    unknown: 'xxx',
    serverError: 'xxx',
    dataAccessBlocked: 'xxx',
    masterAlreadyUsed: 'xxx',
    mstNotConnected: 'xxx',
    mstNotConnectedExtended: 'xxx',
    mstInvalidAC: 'xxx',
    mstInvalidMAC: 'xxx',
    mstConnectedWithGSM: 'xxx',
    mstConnectedWithPLC: 'xxx',
    noSignalDetected: 'xxx',
    unknownError: 'xxx',
    plcError: 'xxx',
    finishInscription: 'xxx',
    tooLongStringError: 'xxx',
    numberFormatError: 'xxx',
    countryIsNotSupported: 'xxx',
    geocoderError: 'xxx',
    rackingPowerError: 'xxx',
    customerNotFound: 'xxx',
    installationUserNotFound: `xxx`,
    wModbusBoxSerialError: 'xxx',
    wModbusBoxDeviceNotFound: 'xxx',
    wModbusBoxDeviceUnavailable: 'xxx',
    wModbusPartDeviceUnavailable: 'xxx',
    wModbusPartSerialError: 'xxx',
    wModbusPartDeviceMissingDevices: 'xxx',
    deletionMyLightMsb: 'xxx',
  },
  ErrorsBack: {
    customerMailAlreadyExist: 'xxx'
  },
  PowerContractType: {
    flat: 'xxx',
    custom: 'xxx',
    predefined: 'xxx'
  },
  PowerContractOption: {
    ejp: 'xxx',
    tempo: 'xxx',
    other: 'xxx'
  },
  StepError: {
    room: 'xxx',
    device: 'xxx',
    deviceVrt: 'xxx',
    greenPlay: 'xxx',
    unknown: 'xxx'
  },
  StepTodo: {
    summary: 'xxx',
    installation: 'xxx',
    functionsNoCounter: 'xxx',
    functionsForbidden: 'xxx'
  },
  ToolsPairing: {
    verifPairing: 'xxx',
    testPairing: 'xxx',
    completeSystem: 'xxx',
    notPermissionToAccessPairingToolkit: 'xxx',
    refreshPower: 'xxx'
  },
  SidebarGuide: {
    gettingStartedGuideLabel: 'xxx',
    hardwareRegistrationTutorialLabel: 'xxx',
    mistakesNotToMakeLabel: 'xxx',
    guideToProceduresLabel: 'xxx',
  }
};
